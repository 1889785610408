* {
    box-sizing: border-box;
}

.wf-active body, .wf-active button, .wf-active input {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
}

:root {
    --white-color: rgb(250, 250, 250);
    --text-color: rgb(28, 28, 28);
    --green-color: rgb(80, 100, 67);
    --dark-green-color: rgb(40, 60, 27);
    --grey-color: rgb(210, 215, 220);
    --dark-color: rgb(120, 122, 125);
}

body {
    margin: 0 auto;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--white-color);
    color: var(--text-color);
    font-size: 1em;
    line-height: 1.49678em;
    height: 100vh;
}

#root {
    width: 100%;
    height: inherit;
}